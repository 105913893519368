import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import "../src/assets/resert.css"
import './styles.scss'

import axios from "./utils/request.js"

import common from "./utils/common.js"
import dayjs from "dayjs"

import en from 'element-ui/lib/locale/lang/en'
import zhHants  from 'element-ui/lib/locale/lang/zh-CN'
import zhHant from 'element-ui/lib/locale/lang/zh-TW'
import jp from 'element-ui/lib/locale/lang/ja'
import kor from 'element-ui/lib/locale/lang/ko'
import vnm from 'element-ui/lib/locale/lang/vi'

import config from './utils/config'



import { i18n } from './locale/index.js' //国际化
let obj = {
  en,
  zhHants,
  zhHant,
  jp,
  kor,
  vnm

}

let local = i18n.locale.replace("-","")

function kefu(type){
  window.open('https://uqddtt.afrgkuxljeikdb.in/index?key=00da0e17fc02eb8a0bb54aba3acfcc54&lang=jp');
  // if (type == 1) {
  //   if (window.localStorage.getItem("token")) {

  //     window.open('/chatlink.html?groupid=5f1bf3b4addcbf7bdadd180be9c41704&language=en&metadata={"email":"' + store.state.userData.userinfo.email + '"}', "_bank")
  //     //  window.location.href='/chatlink.html?groupid=870e6c6d86c4e3bc2efb752da09374b0&language=en&metadata={"email":"'+this.$store.state.userData.userinfo.email+'"}'
  //   } else {

  //     window.open('/chatlink.html?groupid=5f1bf3b4addcbf7bdadd180be9c41704&language=en&metadata={"email":"' + '' + '"}', "_bank")
  //     //  window.location.href='/chatlink.html?groupid=870e6c6d86c4e3bc2efb752da09374b0&language=en&metadata={"email":"'+''+'"}'
  //   }
  // } else {
  //   store.state.kefuDialog = true
  // }
}


Vue.prototype.common = common
Vue.prototype.open_view = common.open_view
Vue.prototype.is_login = common.is_login
Vue.prototype.back = common.back
Vue.prototype.format_money = common.formatMoney
Vue.prototype.format_money_unit = common.formatMoneyUnit
Vue.prototype.format_number = common.formatNumber
Vue.prototype.replace_all = common.replaceAll
Vue.prototype.$copy = common.copy
Vue.prototype.$axios = axios;
Vue.prototype.dayjs = dayjs;
Vue.prototype.BaseURL = config.HTTP_API_URL ;
Vue.prototype.kefu = kefu ;

import LemonIMUI from 'lemon-imui-rx';
import 'lemon-imui-rx/dist/index.css';
Vue.use(LemonIMUI);

Vue.use(ElementUI,{locale:obj[local] })

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
